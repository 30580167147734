






























import { isEmptyOrWhiteSpace, showError, showWarning } from "@/utils/Common";
import AuthMainLayout from "@/views/auth/AuthMainLayout.vue";
import Cookie from "js-cookie";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { AuthMainLayout }
})
export default class Login extends Vue {
  username = "";
  password = "";
  rememberInfo = false;
  loadingData = false;

  get appTitle() {
    return process.env.VUE_APP_TITLE;
  }

  created() {
    const data = Cookie.get("userinfo");
    if (!data) {
      return;
    }
    const info = JSON.parse(data);
    this.username = info.user;
    this.password = info.pass;
  }

  doLogin() {
    if (this.loadingData) {
      return;
    }
    if (isEmptyOrWhiteSpace(this.username)) {
      showWarning("请输入账号/邮箱/手机号");
      return;
    }

    if (isEmptyOrWhiteSpace(this.password)) {
      showWarning("请输入密码");
      return;
    }

    this.loadingData = true;
    const postedData = {
      loginType: "LOGIN_NAME",
      loginName: this.username.trim(),
      password: this.password.trim()
    };

    if (this.rememberInfo) {
      Cookie.set("userinfo", {
        user: this.username.trim(),
        pass: this.password.trim()
      });
    }

    this.$store
      .dispatch("user/login", postedData)
      .then(() => {
        const user = this.$store.getters["user/user"];
        if (!user) {
          return;
        }
        const indexPage = { path: "activities" };
        if (indexPage) {
          this.$router.push({ name: indexPage.path });
        }
      })
      .catch((resp: any) => {
        this.loadingData = false;
        showError(resp.message);
      });
  }

  mounted() {
    const username = this.$route.query?.email as string;
    if (!isEmptyOrWhiteSpace(username)) {
      this.username = decodeURIComponent(username);
    }
  }
}
